export default [
    {
        path: "/editor",
        name: "editor",
        meta: { requiresAuth: true },
        component: () => import('@views/editor/Main.vue'),
        children: [
            {
                path: "",
                name: "editor.index",
                meta: { requiresAuth: true },
                component: () => import('@views/editor/Index.vue'), 
            },
            {
                path: "demo/:id",
                name: "editor.demo",
                meta: { requiresAuth: true },
                component: () => import('@views/editor/EditNew.vue'), 
            },
            {
                path: "/editor/:id/show",
                name: "editor.show",
                props: true,
                meta: { requiresAuth: true },
                component: () => import('@views/editor/Show.vue'), 
            },
            {
                path: "/editor/add",
                name: "editor.add",
                props: true,
                meta: { requiresAuth: true , breadcrumb: { name: 'Add Template' }, is_root: true},
                component: () => import('@views/editor/Edit.vue'),
            },
            {
                path: "/editor/edit/:id",
                name: "editor.edit",
                props: true,
                meta: { requiresAuth: true , breadcrumb: { name: 'Add New Template' }, is_root: true},
                component: () => import('@views/editor/Edit.vue'),
            },
            {
                path: "/editor/:id/edit/:template_id?",
                name: "editor.event.edit",
                props: true,
                meta: { requiresAuth: true , breadcrumb: { name: 'Edit Template' } , is_root: true},
                component: () => import('@views/editor/Edit.vue'),
            }
        ]
    },
]