export default [
    {
        path: "/customers",
        name: "customers",
        meta: { requiresAuth: true },
        component: () => import('@views/customers/Main.vue'),
        children: [
            {
                path: "",
                name: "customers.index",
                meta: { requiresAuth: true, breadcrumb: { name: 'Customer List', is_root: true } },
                component: () => import('@views/customers/Index.vue'), 
            },
            {
                path: "/customers/:id",
                name: "customers.show",
                props: route => ({ id: route.params.id, readonly: true, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Customer' } },
                component: () => import('@views/customers/Edit.vue'), 
            },
            {
                path: "/customers/:id?/edit",
                name: "customers.edit",
                props: route => ({ id: route.params.id, readonly: false, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Edit Customer' } },
                component: () => import('@views/customers/Edit.vue'),
            },
        ]
    },
]