<template>
  <v-app>
    <router-view :key="$route.name" />
    <v-snackbar v-model="localShow" @click="close" :timeout="localTimeout" fixed top :color="color"
      style="z-index: 999999;" dismissible>
      <span v-html="localMessage"></span>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" icon v-bind="attrs" @click="close">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "pinia";
// import { useUserStore } from '@stores/user';
import { useSnackbarStore } from '@stores/snackbar';


export default {
  name: "App",
  data() {
    return {
      localShow: false,
      localMessage: "",
      localTimeout: 5000,
    }
  },
  computed: {
    ...mapState(useSnackbarStore, ["message", "color", "show", "timeout"]),
  },
  methods: {
    ...mapActions(useSnackbarStore, ["close"])
  },
  watch: {
    show(newVal) {
      this.localShow = newVal;
      this.localMessage = this.message;
      this.localTimeout = this.timeout;
    }
  }
};
</script>