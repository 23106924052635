/*
 * This is the initial API interface
 * we set the base URL for the API
 ? Both request & response are logged to the console.
 ! Remove the console logs for production.
*/
import { useUserStore } from "@stores/user";
import axios from "axios";

import { useSnackbarStore } from '@stores/snackbar';

const api_client = axios.create({
  baseURL: window.location.origin + "/api",
  withCredentials: true, // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
api_client.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const userStore = useUserStore();
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 419)
    ) {
      userStore.logout();
    }
    return Promise.reject(error);
  }
);


const notifyInvalidDataErrors = function(errors) {
    let msg = ''
    for (const e in errors) {
        let specs = ''
        if (Array.isArray(errors[e])) {
            specs = errors[e].join(', ')
        }
        msg += `${e} ${specs}, `
    }

    if (msg.length > 0)
        msg = msg.substring(0, msg.length - 2)

    // const snackbar_store = useSnackbarStore()
    // snackbar_store.setError(msg)

    return msg
}

const api_guarded_call = function(api_call, uri, args) {
    try {
        // debugger
        const outcome = api_call(uri, ...args).then(outcome => outcome )
        .catch(err => {
            switch (err.response?.status) {
                case 422:
                    if (err.response?.data?.errors) {
                        const msg = notifyInvalidDataErrors(err.response?.data?.errors)
                        throw new Error(msg ?? 'Error', { cause: 'client' })
                    }
                    else {
                        throw new Error(err.response?.data?.message ?? 'Error', { cause: 'client' })
                    } 
                    break
                default:
                    throw new Error(err.response?.data?.message && err.response.data.message.length > 0 ? err.response.data.message : 'Server Error', { cause: 'server' })
                    break
            }
        })
        return outcome
        return api_call(uri, ...args)
    }
    catch (err) {
        switch (err.response?.status) {
            case 422:
                if (err.response?.data?.errors) {
                    notifyInvalidDataErrors(err.response?.data?.errors)
                    // throw new Error(err.response?.data?.message ?? 'Error', { cause: 'client' })
                }
                else {
                    throw new Error(err.response?.data?.message ?? 'Error', { cause: 'client' })
                } 
                break
            default:
                throw new Error(err.response?.data?.message && err.response.data.message.length > 0 ? err.response.data.message : 'Server Error', { cause: 'server' })
                break
        }
    }
}



export default {
    get(uri, ...args) {
        return api_guarded_call(api_client.get, uri, args) 
    },
    post(uri, ...args) {
        return api_guarded_call(api_client.post, uri, args)
    },
    put(uri, ...args) {
        return api_guarded_call(api_client.put, uri, args)
    },
    delete(uri, ...args) {
        return api_guarded_call(api_client.delete, uri, args)
    },
};