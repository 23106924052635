// import Admin from "./admin.js"
import Auth from "./auth.js"
// import User from "./user.js"
import Event from "./event.js";
import Editor from "./editor.js";
import Template from "./template.js";
import Customer from "./customer.js";
import Provider from "./provider.js";
import Participant from "./participant.js";
import Gate from "./gate.js";
import Receiver from "./receiver.js";
import User from "./user.js";

const routes = [
  {
    path: "/",
    name: "authHome",
    meta: { requiresAuth: true, breadcrumb: { name: 'Events', is_root: true } },
    redirect: to => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { path: '/events' }
    },
    
    component: () => import('@layouts/Main.vue'),
    children: [
    //   ...Admin,
    //   ...User,
      ...Editor,
      ...Template,
      ...Event,
      ...Customer,
      ...Provider,
      ...Participant,
      ...Gate,
      ...Receiver,
      ...User,
    ]
  },
  ...Auth,
  { path: "*", component: () => import('@layouts/NotFound.vue'), },
]

export default routes;