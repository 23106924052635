export default [
    {
        path: "/receivers",
        name: "receivers",
        meta: { requiresAuth: true },
        component: () => import('@views/receivers/Main.vue'),
        children: [
            {
                path: "",
                name: "receivers.index",
                meta: { requiresAuth: true, breadcrumb: { name: 'Receiver List', is_root: true } },
                component: () => import('@views/receivers/Index.vue'), 
            },
            {
                path: "/receivers/:id",
                name: "receivers.show",
                props: route => ({ id: route.params.id, readonly: true, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Receiver' } },
                component: () => import('@views/receivers/Edit.vue'), 
            },
            {
                path: "/receivers/:id?/edit",
                name: "receivers.edit",
                props: route => ({ id: route.params.id, readonly: false, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Edit Receiver' } },
                component: () => import('@views/receivers/Edit.vue'),
            },
        ]
    },
]