export default [
    {
        path: "/participants",
        name: "participants",
        meta: { requiresAuth: true },
        component: () => import('@views/participants/Main.vue'),
        children: [
            {
                path: "",
                name: "participants.index",
                meta: { requiresAuth: true, breadcrumb: { name: 'Participant List', is_root: true } },
                component: () => import('@views/participants/Index.vue'), 
            },
            {
                path: "/participants/:id",
                name: "participants.show",
                props: route => ({ id: route.params.id, readonly: true, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Participant' } },
                component: () => import('@views/participants/Edit.vue'), 
            },
            {
                path: "/events/:eventId/participants/add",
                name: "participants.add",
                props: route => ({ id: route.params.id, readonly: false, eventId: route.params.eventId }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Add Participant' } },
                component: () => import('@views/participants/Edit.vue'),
            },
            {
                path: "/participants/:id?/edit",
                name: "participants.edit",
                props: route => ({ id: route.params.id, readonly: false, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Edit Participant' } },
                component: () => import('@views/participants/Edit.vue'),
            },
        ]
    },
]