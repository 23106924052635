export default [
    {
        path: "/users",
        name: "users",
        meta: { requiresAuth: true },
        component: () => import('@views/users/Main.vue'),
        children: [
            {
                path: "",
                name: "users.index",
                meta: { requiresAuth: true, breadcrumb: { name: 'Users List', is_root: true } },
                component: () => import('@views/users/Index.vue'), 
            },
            {
                path: "/users/:id?/edit",
                name: "users.edit",
                props: route => ({ id: route.params.id, readonly: false, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'Edit User' } },
                component: () => import('@views/users/Edit.vue'),
            },
            {
                path: "/users/:id",
                name: "users.show",
                props: route => ({ id: route.params.id, readonly: true, }),
                meta: { requiresAuth: true, breadcrumb: { name: 'User' } },
                component: () => import('@views/users/Edit.vue'), 
            },
        ]
    },
]