export default [
    {
        path: "/login",
        name: "login",
        // component: () => import(/* webpackChunkName: "login" */ "../views/Layouts/Login"),
        component: () => 
        {
            //  Se il componente per il dominio è presente, ritona quello,
            //  altrimenti ritorna la Login di default:
            return import('@domains/' + Vue.APP_DOMAIN.theme + '/layouts/Login.vue')
                        .catch((e) => {
                            return import('@layouts/Login.vue');
                        });

            // switch (Vue.APP_DOMAIN.theme)
            // {
            //     case 'app':
            //         return import('@layouts/Login.vue');
            //     default:
            //         return import('@domains/' + Vue.APP_DOMAIN.theme + '/layouts/Login.vue').catch((e) => {console.log('ERR', e)});
            // }
        }
        
        //import('@domains/' + Vue.APP_DOMAIN.theme + '/layouts/Login.vue'),
    },
    // {
    //   path: "/register",
    //   name: "register",
    //   component: ()  => import('@layouts/Register.vue'),
    //     // import(/* webpackChunkName: "register" */ "../views/Register"),
    // },gi t
    // {
    //     path: "/reset-password",
    //     name: "ResetPassword",
    //     component: () => import('@layouts/ResetPassword.vue'),
    // },
    // {
    //     path: "/forgot-password",
    //     name: "ForgotPassword",
    //     component: () => import('@layouts/ForgotPassword.vue'),
    // },
]